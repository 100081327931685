import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

// import resume from '../assets/CV/Sagar-Satam-CV2019.pdf'

import about1 from '../assets/images/aboutjiva/jiva1.png'
import about2 from '../assets/images/aboutjiva/jiva2.png'
import about3 from '../assets/images/aboutjiva/jiva3.png'
import about4 from '../assets/images/aboutjiva/jiva4.png'
import about5 from '../assets/images/aboutjiva/jiva5.png'
import about6 from '../assets/images/aboutjiva/jiva6.png'
import about7 from '../assets/images/aboutjiva/jiva7.png'

import jiva_logo from '../assets/images/aboutjiva/jiva-logo.png'

// import about8 from '../assets/images/about/about8.jpg'
// import awardsgroup from '../assets/images/awardsgroup.png'
// import mediumlogo from '../assets/images/mediumlogo.png'


// import award1 from '../assets/images/awards/award1.png'
// import award2 from '../assets/images/awards/award2.png'
// import award3 from '../assets/images/awards/award3.png'
// import award4 from '../assets/images/awards/award4.png'
// import award5 from '../assets/images/awards/award5.png'
// import award6 from '../assets/images/awards/award6.png'
// import award7 from '../assets/images/awards/award7.png'
// import award8 from '../assets/images/awards/award8.png'

const Aboutme = props => (
  <Layout>
    <Helmet
      title="About Me | Portfolio Sagar Satam"
      meta={[
        { name: 'Design', content: 'Portfolio' },
        { name: 'Design', content: 'product design, designer' },
      ]}
    />

    <div id="main" className="alt">
      <section id="one" className="title-block inner">
        <div className="hp-featured">
          <h1 className="hp-featured__title">
            About Jiva
          </h1>
          <div className="hp-featured__content">
          <br />
          <div className="client-image">
          <img className="span-4" src={jiva_logo} alt="jiva logo" />
          </div>
          <br />
          <p className="hp-featured__text">
            Jiva is an AgriTech product & service that aims to create meaningful impact for smallholder farmers in rural Indonesia.
          </p>
          </div>
        </div>
      </section>

    

      <div className="project-wrapper">
      <div className="project-content">
      <section className="process-section">

      <div className="img right">
              <img src={about1} alt="" />
      </div>

      <p className="full-textlarge">
        From being designer #1 to a design team of 25 plus, I played multiple roles as a Service & UX designer — 
        🔍 the researcher, 🦮 the facilitator, 🎬 the orchestrator, ✍️ the experience designer.
      </p>

      <div className="changebg">
      <div className="process">           
            <div className="process-left">
              <h4>The smallholder agricultural supply chain is broken.</h4>
            </div>
            <div className="process-right">
              
              <p>
              Today, an estimated 500 million smallholder farmers 
              produce up to 70 percent of the world’s food; 
              yet 90 percent of smallholders earn less than 
              the living income standard.
              </p> 
              
              <p>
              By improving the earning capacity of smallholder 
              farmers and simultaneously teaching them more effective, 
              efficient, and sustainable farming methods, we can lift 
              millions out of poverty. 
              </p>

              </div>
              <div className="img left">
              <img src={about2} alt="" />
              </div>
      </div>
      </div>
      
      
      <p className="full-textlarge">
      Jiva does this through providing four key services to small holder farmers in Indonesia.
      </p>

        <div className="img right">
              <img src={about3} alt="" />
        </div>
          


          <div className="changebg">
      <div className="process">           
            <div className="process-left">
              <h4>Context I am designing for — the blend of human-physical-digital.</h4>
            </div>
            <div className="process-right">
              
              <p>
              As we build digital products for farmers and the rural 
              community, we cannot neglect their physical networks, 
              their dependence on traditional-trusted ways of working 
              and must embrace the delicate aspects of human connections 
              and strong sense of community. 
              </p> 
              
              <p>
              We thus deliver our products & services through an
               integrated ecosystem of farmers, village-level entrepreneurs, 
               and agri-retailers, who are supported by a blend of Physical 
               and Digital approaches. 
              </p>

              </div>
              <div className="img left">
              <img src={about4} alt="" />
              </div>
      </div>
      </div>

      <div className="img left">
              <img src={about5} alt="" />
              </div>

              <div className="img left">
              <img src={about6} alt="" />
              </div>

              <div className="img left">
              <img src={about7} alt="" />
              </div>

      

      {/* <div className="process">           
            <div className="process-left">
              <h4>At things I was involved in working closely on 4 key projects —</h4>
            </div>
            <div className="img left">
              <img src={about4} alt="" />
            </div>
      </div> */}



      </section>
      </div>
      </div>

      <br />


      

      <section id="footertopcta" className="inner">
        <p className="text arrow-front">
        <a href="/work/service-design-acrole">check out the project on redesigning the role of Activation Coordinator</a>
        </p>
        {/* <p className="text arrow-front">
        <a href="/what-offer">now that you know a bit about me, 
        find out what i can offer you</a>
        </p> */}
      </section>
      <br />

    </div>
  </Layout>
)

export default Aboutme
